<template>
  <section class="lista-proposta">
    <div class="m-1 mr-4 px-0 proposta">
      <breadcrumb title="Propostas" actualPage="Lista de propostas" />
    </div>

    <div
      class="grid grid-cols-3 mt-8 mb-6 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2"
    >
      <location-dropdown></location-dropdown>

      <filtro ref="filtro" :titleName="'Propostas'" :isFilter="filtro">
        <div class="pt-2 pb-6 px-4">
          <div class="flex flex-col md:flex-row">
            <vs-input
              size="large"
              label-placeholder="Nro da Proposta"
              class="w-full md:w-3/5 md:pr-3"
              v-model="filterValue.number"
            />
            <v-select
              placeholder="Status da Proposta"
              class="w-full mt-8 md:w-2/5 md:mt-4"
              :show-no-options="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedProposalStatusOption"
              @input="setProposalStatusOption(selectedProposalStatusOption)"
              label="name"
              :options="proposalStatusOptionsMixin"
              size="large"
            >
            </v-select>
          </div>

          <div class="flex flex-col mt-4 md:mt-8 md:flex-row">
            <vs-input
              size="large"
              label-placeholder="Nome do Tomador"
              class="w-full mt-4 md:w-3/5 md:pr-3 md:m-0"
              v-model="filterValue.policyHolderName"
            />
            <vs-input
              size="large"
              label-placeholder="CNPJ Tomador"
              class="w-full mt-8 md:w-2/5 md:m-0"
              v-model="filterValue.policyHolderCpfCnpj"
            />
          </div>

          <div class="flex mt-4 md:mt-8 flex-col md:flex-row">
            <vs-input
              size="large"
              label-placeholder="Nome do Segurado"
              class="w-full mt-4 md:w-3/5 md:pr-3 md:m-0"
              v-model="filterValue.insuredName"
            />
            <vs-input
              size="large"
              label-placeholder="CNPJ Segurado"
              class="w-full mt-8 md:w-2/5 md:m-0"
              v-model="filterValue.insuredCpfCnpj"
            />
          </div>
          <div class="flex flex-row mt-5 w-3/5">
            <h6>Data de Cadastro da Proposta</h6>
          </div>

          <div class="flex flex-row mt-4">
            <div class="w-full flex">
              <span class=" flex-col w-1/2">
                <p>Data inicial</p>
                <datepicker
                  :language="language"
                  format="dd/MM/yyyy"
                  class="pr-4 w-full calendar-initial-date"
                  v-model="filterValue.startDate"
                ></datepicker>
              </span>

              <span class="flex-col w-1/2">
                <p>Data final</p>
                <datepicker
                  :language="language"
                  format="dd/MM/yyyy"
                  class="pr-4 w-full calendar-final-date"
                  v-model="filterValue.endDate"
                ></datepicker>
              </span>
            </div>
          </div>

          <vs-row>
            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12"
              ><p class="label-content label" style="margin-left: -12px;">
                Tipo de proposta:
              </p>
            </vs-col>
            <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12"
              ><p class="label-content label" style="margin-left: -12px;">
                Tipo endosso:
              </p>
            </vs-col>
            <vs-col class="flex justify-between  pt-2" vs-lg="6" size="small">
              <el-select
                v-model="filterValue.proposalType"
                placeholder="Selecione"
                @change="typeChange()"
              >
                <el-option
                  v-for="item in typesProposal"
                  :key="item.Id"
                  :label="item.label"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </vs-col>
            <vs-col class="flex justify-between  pt-2" vs-lg="6" size="small">
              <el-select
                v-model="filterValue.endossoType"
                placeholder="Selecione"
                @change="typeChangeEndosso()"
              >
                <el-option
                  v-for="item in subtiposEndosso"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </vs-col>
          </vs-row>

          <div class="flex flex-row-reverse mt-4">
            <vs-button
              @click.native="filterProposals(true)"
              color="primary"
              type="filled"
              class="ml-4"
              >Aplicar filtro</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon="clear"
              @click.native="clearFilter()"
              >Limpar filtro</vs-button
            >
          </div>
        </div>
      </filtro>

      <vs-button
        router-link
        @click="gotoNovaProposta"
        class="max-w-lg w-full nova-proposta-btn"
        v-if="
          perfilHelpers.checkPermission([
            perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS
          ])
        "
        :disabled="
          this.bDocumentacaoPendente ||
            false ||
            this.bAguardandoAnalise ||
            this.bValidacaoCompliancePendente
        "
      >
        Nova proposta
      </vs-button>
    </div>

    <el-dialog
      title=""
      :visible.sync="modalDocuments"
      width="50%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      style="word-wrap: break-word;"
    >
      <h3>
        Você ainda tem documentos pendentes e/ou aguardando análise pela
        seguradora
      </h3>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goTO('/corretor')"
          >Ir para dados da corretora</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="modalDocumentsAwait"
      width="50%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      style="word-wrap: break-word; padding-bottom: 150px;"
    >
      <h3 style="padding-bottom: 15px; color: #0e65a5;">
        Cadastro concluído, aguarde a aprovação da Seguradora!
      </h3>
      <el-alert
        title="Atenção!"
        type="warning"
        description="Para ter acesso a plataforma aguarde a liberação do cadastro pela seguradora!"
        show-icon
      >
      </el-alert>
      <span slot="footer" class="dialog-footer text-center">
        <vs-button @click="onLogout()">
          Sair
        </vs-button>
      </span>
    </el-dialog>

    <vs-popup
      title="Agurdando análise pela seguradora!"
      :active.sync="modalAnalise"
      class="popup-width-auto"
    >
      <p style="padding: 6px">
        Aguarde a aprovação da seguradora para continuar com a proposta
      </p>
      <vs-button
        router-link
        to="/corretor"
        class="w-full sm:w-7/12 sm:float-right"
        @click="modalAnalise = false"
      >
        Ir para dados da corretora
      </vs-button>
    </vs-popup>

    <div class="table-content mt-2">
      <template v-if="proposals.length > 0">
        <vs-table
          class="table-list"
          :data="proposals || []"
          noDataText="Nenhuma proposta encontrada"
          :hoverFlat="true"
        >
          <template slot="thead">
            <vs-th></vs-th>
            <vs-th sort-key="numero" class="text-center">Nro.</vs-th>
            <vs-th sort-key="numero" class="text-center">Tipo</vs-th>
            <vs-th v-if="podeExibirCanal" sort-key="Channel" class="text-center"
              >Canal</vs-th
            >
            <vs-th sort-key="policyHolderName" class="text-center"
              >Tomador</vs-th
            >
            <vs-th sort-key="segurado" class="text-center">Segurado</vs-th>
            <vs-th sort-key="modalidade" class="text-center">Modalidade</vs-th>
            <vs-th sort-key="status" class="text-center">Status</vs-th>
            <vs-th sort-key="dataProposta" class="text-center"
              >Data da proposta</vs-th
            >
            <vs-th sort-key="is" class="text-center">IS</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="text-center w-1/12">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-proposta"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu
                    class="btn-action-proposta-options w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  >
                    <vs-dropdown-item @click="goToProposal(data[indextr])">
                      <p class="text-base px-6 py-2">
                        <span>{{
                          data[indextr].StatusId == 1
                            ? "Visualizar proposta"
                            : "Visualizar proposta"
                        }}</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="
                        data[indextr].TypeName === 'Renovação' &&
                          canCancelItem(data[indextr].StatusId)
                      "
                      @click="cancelarRenovacao(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Cancelar proposta</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      divider
                      @click="disclamerCancel(data[indextr])"
                      v-else-if="canCancelItem(data[indextr].StatusId)"
                    >
                      <p class="text-base px-6 py-2">
                        <span>{{
                          data[indextr].StatusId == 1
                            ? "Cancelar proposta"
                            : "Cancelar proposta"
                        }}</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      divider
                      @click="downloadMinuteClick(data[indextr].UniqueId)"
                      v-if="
                        canDownloadMinute(
                          data[indextr].StatusId,
                          data[indextr].PolicyHolderCanViewLimitsAndRates
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>{{
                          data[indextr].StatusId == 1
                            ? "Imprimir proposta"
                            : "Imprimir minuta"
                        }}</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
              <vs-td :data="data[indextr].Number" class="text-center w-1/12">
                <div class="flex">
                  <b>{{ data[indextr].Number }}</b>
                  <vx-tooltip
                    v-if="data[indextr].StatusId === 6"
                    text="Proposta ainda não se encontra para análise da seguradora. Verifique as pendências para seguir com o processo de emissão"
                    position="top"
                  >
                    <i class="material-icons alert text-xl ml-1">info</i>
                  </vx-tooltip>
                </div>
              </vs-td>

              <vs-td :data="data[indextr].TypeId" class="text-center w-1/12">
                <!-- <b>{{ data[indextr].TypeId == 1 ? "Proposta" : "Endosso" }}</b> -->
                <b>{{ data[indextr].TypeName }}</b>
              </vs-td>

              <vs-td
                v-if="podeExibirCanal"
                :data="data[indextr].NomeTipoCanal"
                class="text-center w-1/4"
              >
                <b>{{
                  data[indextr].NomeTipoCanal
                    ? data[indextr].NomeTipoCanal
                    : "Canal não definido"
                }}</b>
              </vs-td>

              <vs-td
                :data="data[indextr].PolicyHolderCpfCnpj"
                class="text-center w-1/5"
              >
                <b>{{ data[indextr].PolicyHolderName }}</b>
                <small class="block">
                  {{ data[indextr].PolicyHolderCpfCnpj | maskCpfCnpj }}
                </small>
              </vs-td>

              <vs-td
                :data="data[indextr].InsuredCpfCnpj"
                class="text-center w-1/5"
              >
                <b>{{ data[indextr].InsuredName }}</b>
                <small class="block">
                  {{ data[indextr].InsuredCpfCnpj | maskCpfCnpj }}
                </small>
              </vs-td>

              <vs-td
                :data="data[indextr].ModalityName"
                class="text-center w-1/12"
              >
                <b>{{ data[indextr].ModalityName }}</b>
              </vs-td>

              <vs-td
                :data="data[indextr].StatusName"
                class="text-center w-1/12"
              >
                <vs-button
                  class="status"
                  type="filled"
                  size="small"
                  :color="
                    data[indextr].StatusId
                      | setColorStatus(data[indextr].TimelineStatusId)
                  "
                >
                  {{ data[indextr].StatusId | setLabelStatus }}
                </vs-button>
              </vs-td>

              <vs-td
                :data="data[indextr].CreateDate"
                class="text-center w-1/12"
              >
                <b>{{
                  new Date(data[indextr].CreateDate)
                    | dateFormat("DD/MM/YYYY - HH:mm")
                }}</b>
              </vs-td>

              <vs-td
                :data="data[indextr].AwardAmount"
                class="text-center w-1/12"
              >
                <currency-input
                  class="input-currency font-semibold text-center"
                  v-model="data[indextr].InsuredAmountValue"
                  disabled="true"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
        >
        </vs-pagination>
      </template>
      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p>Você ainda não possui nenhuma proposta cadastrada.</p>
        </div>
      </template>
    </div>
    <el-dialog
      title=""
      :visible.sync="modalDocsCancelProposal"
      width="50%"
      :show-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      style="word-wrap: break-word; padding-bottom: 150px;"
    >
      <h3 style="padding-bottom: 15px; color: #0e65a5;">
        Solicitação de cancelamento enviada com sucesso!
      </h3>
      <el-alert
        title="Atenção!"
        type="warning"
        description="Caso seja necessário, faça o upload de documentos para análise da seguradora!"
        show-icon
      >
      </el-alert>
      <div class="content-upload mt-5 ">
        <p v-if="actualCancelProposal.TypeId != 4">
          <b>Observação</b>
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="Descreva o motivo do cancelamento"
            v-model="payloadCancelProposal.Observacao"
          >
          </el-input>
        </p>
        <p class="text-center">
          <vs-button @click="addDocumentList()" class=" mt-4">
            <i class="el-icon-circle-plus-outline"></i> Adicionar Documento
          </vs-button>
        </p>

        <div class="listDocs mt-4">
          <ul>
            <li
              v-for="(item, index) in payloadCancelProposal.Documents"
              :key="index"
            >
              <label> {{ item.DocumentName }}</label>
              <p>Arquivos: {{ item.files.length }}</p>
              <i
                class="el-icon-circle-close"
                @click="removeDocuments(index)"
              ></i>
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer text-center mt-10">
        <vs-button
          @click="modalDocsCancelProposal = false"
          color="danger"
          class="mr-2"
        >
          Sair
        </vs-button>
        <vs-button @click="sendDocuments()">
          Enviar Documentos
        </vs-button>
      </span>
    </el-dialog>
    <base64Upload
      v-if="modalUpload"
      :modalUpload="modalUpload"
      :documentoName="''"
      @callBackUpload="callBackUpload"
      @closeModal="closeModal"
    />

    <popup-base
      @close="
        modalcancelationReason = false;
        cancelationReason = null;
      "
      :showModal="modalcancelationReason"
      size="sm"
      class="success-modal onpoint-successmodal"
      title=" "
      :button-close-hidden="false"
    >
      <template slot="content">
        <div>
          <span class="mb-2"
            >Selecione o motivo para o cancelamento
            <strong style="color:red">*</strong>
          </span>
          <el-select v-model="cancelationReason" placeholder="escolha o motivo">
            <el-option
              v-for="item in cancelationReasonList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </template>

      <template slot="footer">
        <div class="text-right mt-5  w-full">
          <vs-button
            style="margin-right: 5px"
            @click="
              modalcancelationReason = false;
              cancelationReason = null;
            "
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button
            @click.native="disclamerCancelRenovacao()"
            color="danger"
            v-if="cancelationReason"
          >
            <span class="font-semibold pr-2 pl-2">
              Cancelar proposta
            </span>
          </vs-button>
          <vs-button disabled color="danger" v-else>
            <span class="font-semibold pr-2 pl-2"> Cancelar proposta</span>
          </vs-button>
        </div>
      </template>
    </popup-base>
  </section>
</template>

<script>
import Filtro from "@/components/Filtro/Filtro.vue";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import LocationDropdown from "@/components/location-dropdown/LocationDropdown";
import { TheMask } from "vue-the-mask";
import base64Upload from "@/components/base64-Upload/cancel_proposal_upload.vue";
import optionsStaticMixin from "@/mixins/optionsStaticMixin";
import * as Utils from "@/utils/utils";
import * as Enums from "@/utils/enums";
import * as authUtils from "@/utils/auth";
//import { mapGetters, mapActions } from 'vuex'
import axiosInstance from "@/axios";
import { mapGetters, mapActions } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import VxTourVue from "../../components/VxTour.vue";

import * as Permissoes from "@/utils/permissions.js"
import mapfreProvider from "@/providers/mapfre-provider.js";

export default {
  name: "lista-propostas",
  components: {
    Datepicker,
    Filtro,
    Breadcrumb,
    TheMask,
    LocationDropdown,
    base64Upload
  },
  mixins: [optionsStaticMixin],
  data() {
    return {
      ProposalTimelineStatusId: null,
      proposals: [],
      language: ptBR,
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 10,
      numberOfPages: 0,
      selectedProposalStatusOption: undefined,
      filterValue: {
        number: "",
        statusId: "",
        policyHolderName: "",
        policyHolderCpfCnpj: "",
        insuredName: "",
        insuredCpfCnpj: "",
        startDate: "",
        endDate: "",
        proposalType: null
      },
      subtiposEndosso: false,
      modalDocuments: false,
      modalDocumentsAwait: false,
      modalAnalise: false,
      filtro: false,
      perfilHelpers: PerfilHelpers,
      modalDocsCancelProposal: false,
      actualCancelProposal: false,
      modalUpload: false,
      payloadCancelProposal: {
        Observacao: "",
        ProposalId: null,
        Documents: []
      },
      typesProposal: [
        {
          label: "Proposta",
          Id: 1
        },
        {
          label: "Endosso",
          Id: 2
        },
        {
          label: "Renovação",
          Id: 4
        }
      ],
      permissoesCorretorMapfre: [],
      modalcancelationReason: false,
      selectProposalRenovacao: false,
      cancelationReasonList: [
        "Fim do Processo",
        "Transito Julgado",
        "Extinção do Processo",
        "Substituição de apólice por outra de garantia",
        "Substituição de apólice por outra de Outra congênere"
      ],
      cancelationReason: null
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.filterProposals();
    }
  },
  async mounted() {
    this.carregarCorretora();

    await this.$onpoint.loading(async () => {
      return await this.pegarPermissoesMapfre();
    });
  },
  created() {
    this.ObterSubtiposEndosso();
    this.$appInsights.trackPageView({
      name: "propostas",
      url: window.location.href
    });
  },
  computed: {
    ...mapGetters("cadastro-corretora", [
      "bDocumentacaoPendente",
      "bAguardandoAnalise",
      "bValidacaoCompliancePendente"
    ]),

    podeExibirCanal() {
      return this.permissoesCorretorMapfre.includes("ExibirCanal");
    }
  },
  methods: {
    ...mapActions("cadastro-corretora", ["getCorretora"]),
    ...mapActions("propostas-module", ["gerarMinuta"]),
    ...mapActions("proposta-module", ["limparEstado"]),
    ObterSubtiposEndosso() {
      return axiosInstance
        .get(`/api/Endorsement/ObterSubtiposEndosso`)
        .then(response => {
          this.subtiposEndosso = response.data.Response;
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },
    async sendDocuments() {
      if (this.actualCancelProposal.TypeId === 4) {
        let payload = {
          ProposalId: this.actualCancelProposal.Id,
          ProposalUniqueId: this.actualCancelProposal.UniqueId,
          CancelationReason: this.cancelationReason,
          Documents: this.payloadCancelProposal.Documents
        };
        await this.$onpoint.loadingModal(
          "Estamos enviando sua solicitação ...",
          async () => {
            return await axiosInstance
              .post(
                `api/proposal/CancelProposalRenewWithCardPipeProcess`,
                payload
              )
              .then(result => {
                this.$notify({
                  title: "Sucesso",
                  message: "Upload do arquivo realizado com sucesso!",
                  type: "success"
                });
                this.modalDocsCancelProposal = false;
                 this.cancelationReason = null;
              })
              .catch(errors =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          }
        );
      } else {
        this.payloadCancelProposal.ProposalId = this.actualCancelProposal.Id;
        await this.$onpoint.loadingModal(
          "Estamos enviando sua solicitação ...",
          async () => {
            return await axiosInstance
              .post(
                `api/Endorsement/UploadDocumentosCancelarEndosso`,
                this.payloadCancelProposal
              )
              .then(result => {
                this.$notify({
                  title: "Sucesso",
                  message: "Upload do arquivo realizado com sucesso!",
                  type: "success"
                });
                this.modalDocsCancelProposal = false;
              })
              .catch(errors =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          }
        );
      }
    },
    removeDocuments(index) {
      this.payloadCancelProposal.Documents.splice(index, 1);
    },
    addDocumentList() {
      this.modalUpload = true;
    },
    closeModal() {
      this.modalUpload = false;
    },
    callBackUpload(callback) {
      this.modalUpload = false;
      let payload = {
        DocumentName: callback.title,
        EndorsementRequestDocumentId: 4,
        files: callback.docs
      };
      this.payloadCancelProposal.Documents.push(payload);
    },

    typeChange() {
      if (this.filterValue.proposalType === 1) {
        this.filterValue.endossoType = null;
      }
      this.$forceUpdate();
    },
    typeChangeEndosso() {
      this.filterValue.proposalType = 2;
      this.$forceUpdate();
    },
    goTO(url) {
      this.$router.push(url);
    },
    onLogout() {
      this.$appInsights.trackEvent({ name: "logout", properties: { place: "listar-propostas", action: "button-click", slug: "logout-modal-primeiro-acesso" }});
      authUtils.logout();
    },
    carregarCorretora() {
      if (
        this.bDocumentacaoPendente == undefined ||
        this.bDocumentacaoPendente == true ||
        this.bAguardandoAnalise == undefined ||
        this.bAguardandoAnalise == true
      ) {
        this.$onpoint.loading(() => {
          return this.getCorretora()
            .then(response => {
              if (this.bDocumentacaoPendente) {
                this.modalDocuments = true;
              }
              if (this.bValidacaoCompliancePendente) {
                this.modalDocumentsAwait = true;
                this.modalDocuments = false;
              }
              // this.modalAnalise = this.bAguardandoAnalise;

              if (!this.bDocumentacaoPendente && !this.bAguardandoAnalise)
                return this.loadProposals();
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        });
      } else {
        return this.loadProposals();
      }
    },

    setProposalStatusOption(option) {
      if (option) {
        this.ProposalTimelineStatusId = option.ProposalTimelineStatusId;
        this.filterValue.statusId = option.typeId;
      } else {
        this.ProposalTimelineStatusId = null;
      }
    },

    async downloadMinuteClick(proposalUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.gerarMinuta(proposalUniqueId)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    disclamerCancel(proposal) {
      this.$confirm(
        "Essa ação tem efeito permanente, deseja continuar?",
        "Aviso!",
        {
          confirmButtonText: "Confirmar",
          cancelButtonText: "Voltar",
          type: "warning"
        }
      ).then(() => {
        this.cancelProposal(proposal, false);
      });
    },
    disclamerCancelRenovacao() {
      this.$confirm(
        "Essa ação tem efeito permanente, deseja continuar?",
        "Aviso!",
        {
          confirmButtonText: "Confirmar",
          cancelButtonText: "Voltar",
          type: "warning"
        }
      ).then(() => {
        this.cancelProposal(this.selectProposalRenovacao, true);
      });
    },
    async cancelProposal(proposal, renovacao) {
      this.actualCancelProposal = proposal;
      await this.$onpoint.loadingModal("Cancelando a proposta...", async () => {
        return await axiosInstance
          .post(
            `api/Proposal/CancelProposal?ProposalUniqueId=${proposal.UniqueId}`
          )
          .then(result => {
            this.$notify({
              title: "Success",
              message: "Solicitação de cancelamento enviada com sucesso!",
              type: "success"
            });
            this.modalcancelationReason = false;
            if (result.data.Response === true) {
              this.cancelationReason = null;
              return this.filterProposals();
            } else {
              if (proposal.TypeName === "Renovação") {
                this.modalDocsCancelProposal = true;
              } else if (proposal.TypeId == 2) {
                this.modalDocsCancelProposal = true;
              }
            }
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    async goToProposal(proposal) {
      if (proposal.StatusId === 1) {
        //status COTAÇÃO
        if (proposal.TypeId === 1) {
          this.$router.push({
            name: "editar-proposta",
            params: { propostaUniqueId: proposal.UniqueId }
          });
        }

        if (proposal.TypeId === 2) {
          await this.$onpoint.loading(async () => {
            return await axiosInstance
              .post(
                `api/Proposal/CancelProposal?ProposalUniqueId=${proposal.UniqueId}`
              )
              .then(async result => {
                await this.clearFilter();
              })
              .then(result => {
                this.$onpoint.errorModal(
                  "Identificamos um erro no processo e esta cotação não pode ser utilizada. Por favor, reinicie a proposta do endosso."
                );
              })
              .catch(errors =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          });
        }
      } else {
        this.$router.push({
          name: "timeline-proposta",
          params: { propostaUniqueId: proposal.UniqueId }
        });
      }
    },

    async clearFilter() {
      this.ProposalTimelineStatusId = null;
      this.selectedProposalStatusOption = undefined;
      this.filterValue = {};
      this.currentPage = 1;
      await this.filterProposals();
    },

    async filterProposals(setCurrentPage) {
      this.filtro = !this.filtro;
      if (setCurrentPage) this.currentPage = 1;

      await this.loadProposals(
        this.filterValue.number,
        this.filterValue.policyHolderName,
        this.filterValue.statusId,
        this.filterValue.policyHolderCpfCnpj
          ? this.$utils.removeSpecialChars(this.filterValue.policyHolderCpfCnpj)
          : this.filterValue.policyHolderCpfCnpj,
        this.filterValue.insuredName,
        this.filterValue.insuredCpfCnpj
          ? this.$utils.removeSpecialChars(this.filterValue.insuredCpfCnpj)
          : this.filterValue.insuredCpfCnpj,
        this.filterValue.startDate,
        this.filterValue.endDate,
        this.filterValue.proposalType || "",
        this.filterValue.endossoType || ""
      );
    },

    async loadProposals(
      number = "",
      policyHolderName = "",
      statusId = "",
      policyHolderCpfCnpj = "",
      insuredName = "",
      insuredCpfCnpj = "",
      startDate = "",
      endDate = "",
      proposalType = "",
      endossoType = ""
    ) {
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `api/Proposal/SearchProposals?Number=${number}&PolicyHolderName=${policyHolderName}&StatusId=${statusId}&PolicyHolderCpfCnpj=${policyHolderCpfCnpj}&InsuredName=${insuredName}&InsuredCpfCnpj=${insuredCpfCnpj}&StartDate=${Utils.dateToDDMMYYYY(
              startDate
            )}&EndDate=${Utils.dateToDDMMYYYY(endDate)}&currentPage=${
              this.currentPage
            }&rowsPerPage=${
              this.rowsPerPage
            }&ProposalType=${proposalType}&PolicyEndorsementReasonBaseId=${endossoType}&ProposalTimelineStatusId=${
              this.ProposalTimelineStatusId
            }`
          )
          .then(response => {
            response = response.data.Response;
            this.proposals = response.Data;

            this.totalItems = response.NumberOfRows;
            this.numberOfPages = response.NumberOfPages;
            this.limparEstado();
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    canDownloadMinute(proposalStatusId, policyHolderCanViewLimitsAndRates) {
      return (
        proposalStatusId != Enums.ProposalStatus.Emitida &&
        proposalStatusId != Enums.ProposalStatus.Recusada &&
        proposalStatusId != Enums.ProposalStatus.Cancelada &&
        proposalStatusId != Enums.ProposalStatus.EmAnalise &&
        proposalStatusId == Enums.ProposalStatus.Cotacao &&
        policyHolderCanViewLimitsAndRates == true
      );
    },
    canCancelItem(proposalStatusId) {
      return (
        proposalStatusId != Enums.ProposalStatus.Emitida &&
        proposalStatusId != Enums.ProposalStatus.Recusada &&
        proposalStatusId != Enums.ProposalStatus.Cancelada
      );
    },

    async pegarPermissoesMapfre() {
      this.permissoesCorretorMapfre = [];
      try {
        this.permissoesCorretorMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.PROPOSTA);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    cancelarRenovacao(proposta) {
      this.selectProposalRenovacao = proposta;
      this.modalcancelationReason = true;
    },

    gotoNovaProposta() {
      this.$appInsights.trackEvent({ name: "go-to-nova-proposta", properties: { place: "listar-propostas", action: "button-click", slug: "go-to-nova-proposta" }});
      this.$router.push({ name: "nova-proposta" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";
.input-currency {
  border: none;
  background: transparent;
  color: inherit;
}

.lista-proposta .container {
  max-width: 99% !important;
}

.status {
  width: 11em !important;
  height: 35px;
  padding: 0.5em 1em !important;
  pointer-events: none;
}

b {
  font-weight: 600;
}

::v-deep .vs__dropdown-toggle {
  background: white;
  border: none;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
    }

    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      max-width: 370px;
    }

    @include lg {
      margin-top: -64px;
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .vs-table--search-input {
    border: 0 !important;
    height: 47px;
    width: 300px;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }

  .btn-action-proposta {
    cursor: pointer;
    width: 135px;
  }
}

.btn-action-proposta-options {
  ::v-deep .vs-dropdown--menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>

<style lang="scss">
#filtro-open,
.filtro-open {
  .vs__dropdown-toggle {
    border: 1px solid rgba(60, 60, 60, 0.26);
    box-shadow: 0 0;

    &:focus-within {
      border: 1px solid rgba(var(--vs-primary), 1) !important;
    }
  }

  .vdp-datepicker input {
    height: 47px !important;

    &:focus {
      border: 1px solid rgba(var(--vs-primary), 1) !important;
    }
  }
}
.alert {
  color: #ff9f43;
}

.calendar-initial-date .vdp-datepicker__calendar,
.calendar-final-date .vdp-datepicker__calendar {
  top: -270px;
  z-index: 999;
}
#filtro-open .vs-popup {
  min-width: 1100px !important;
}
#filtro-open .el-radio {
  margin-bottom: 10px !important;
}
</style>

<style scoped>
.listDocs ul {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.listDocs li {
  width: max-content;
  margin-right: 5px;
  border-radius: 12px;
  border: 2px solid rgba(var(--vs-primary), 0.8) !important;
  padding: 5px 10px;
  position: relative;
  padding-right: 25px;
  margin-bottom: 4px;
}

.listDocs li i {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  cursor: pointer;
}

.listDocs li label {
  font-weight: bold;
}
</style>
